import { CSSProperties, ForwardedRef, forwardRef } from 'react';

import ButtonCard from '~/components/molecules/BentoCards/ButtonCard/ButtonCard';
import FeatureCard from '~/components/molecules/BentoCards/FeatureCard/FeatureCard';
import SocialProofCard from '~/components/molecules/BentoCards/SocialProofCard/SocialProofCard';
import StatsCard from '~/components/molecules/BentoCards/StatsCard/StatsCard';
import ToutCard from '~/components/molecules/BentoCards/ToutCard/ToutCard';
import Shadow from '~/components/molecules/Shadow/Shadow';
import { cn } from '~/utils';

import styles from './CardWrapper.module.css';
import { CardWrapperProps } from './CardWrapper.types';

/**
 * The card wrapper component renders the appropriate card component based on
 * the type. We handle the forwarded ref here so that even if the card
 * component type is not recognized we do not break the bentos.
 *
 * @param props Card wrapper properties
 * @returns Card component instance or error message
 */
const CardWrapper = (
  props: CardWrapperProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  let CardComponent: JSX.Element | null = null;

  switch (props._type) {
    case 'card':
      CardComponent = <FeatureCard {...props} />;
      break;
    case 'bento.statsCard':
      CardComponent = <StatsCard {...props} />;
      break;
    case 'bento.socialProofCard':
      CardComponent = <SocialProofCard {...props} />;
      break;
    case 'bento.buttonCard':
      CardComponent = <ButtonCard {...props} />;
      break;
    case 'bento.toutCard':
      CardComponent = <ToutCard {...props} />;
      break;
  }

  if (CardComponent === null) {
    CardComponent = (
      <div className={styles.errUnknownCardType}>
        CardWrapper: Unknown card type &quot;{props._type}&quot;
      </div>
    );
  }

  return (
    <Shadow
      ref={ref}
      className={cn(styles.shadow, props.className)}
      style={{ '--card-index': props.index } as CSSProperties}
    >
      {CardComponent}
    </Shadow>
  );
};

export default forwardRef(CardWrapper);
