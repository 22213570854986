/*
exporting these tokens with correct JS doc annotation so they can be read as ts and made into union types
https://stackoverflow.com/questions/74184312/how-can-i-build-a-typescript-string-literal-type-with-an-imported-array
*/
const lockups = /** @type {const} */ ([
  "all",
  "title1Block",
  "title1StandoutDevice",
  "title2Block",
  "title2Pothole",
  "title2SideBySide",
  "title3Block",
  "title4Form",
  "title4Customers",
  "title4Block",
  "title4Tout",
  "title4Quote",
  "title4SideBySide",
  "title5CardGrid",
  "title5SideBySide",
  "title5Keyline",
  "title5Hatchet",
  "title6Bento",
  "title6Tout",
  "titleCarouselBlock",
  "bodyXLSpeedbump",
  "bodyXLManifesto",
  "buttonCard",
  "caseStudyTitle",
  "caseStudyStats",
  "caseStudyBody",
  "caseStudyMeta",
  "caseStudyIntro",
  "caseStudyText",
  "formDisclaimer",
  "cardGridEditorial",
  "heroImageWall",
  "all",
  "body",
  "announcementBanner",
  "prompter",
  "statisticTile",
  "legacyPothole",
  "floatingVideoPreview",
]);

module.exports = lockups;
