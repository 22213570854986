'use client';
import { gsap } from 'gsap';
import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import useUIStore from '~/state/ui';
import { cn, useIsomorphicLayoutEffect as useLayoutEffect } from '~/utils';
import isBreakpointOrGreater from '~/utils/isBreakpointOrGreater';

import Card from '../Card/Card';
import { CardWrapperProps, CardWrapperRef } from './CardWraper.types';
import styles from './CardWrapper.module.css';
import {
  enterProgressTransformer,
  leaveProgressTransformer,
  nextProgressTransformer,
} from './CardWrapper.utils';

const CardWrapper = (
  {
    index,
    total,
    className,
    isGlowHidden = true,
    layers,
    content,
    bleedOrientation,
  }: CardWrapperProps,
  ref: ForwardedRef<CardWrapperRef>,
) => {
  const $element = useRef<HTMLDivElement>(null);
  const enterProgressSetter = useRef<(value: number) => void>();
  const leaveProgressSetter = useRef<(value: number) => void>();
  const nextProgressSetter = useRef<(value: number) => void>();

  const animation = useRef<GSAPTimeline>();
  const [isInteractive, setIsInteractive] = useState(false);
  const breakpoint = useUIStore((state) => state.breakpoint);

  const isComputedStyleComplete = useUIStore(
    (state) => state.isComputedStyleComplete,
  );

  const updateInteractiveState = useCallback(
    (progress: number, isInView: boolean) => {
      if (1 - progress < 0.15 || !isInView) {
        setIsInteractive(false);
      } else {
        setIsInteractive(true);
      }
    },
    [],
  );

  useImperativeHandle(
    ref,
    () => ({
      get $element() {
        return $element;
      },
      setProgress(progress: number, isInView: boolean) {
        if (enterProgressSetter.current) {
          enterProgressSetter.current(enterProgressTransformer(progress));
        }

        if (leaveProgressSetter.current)
          leaveProgressSetter.current(leaveProgressTransformer(progress));

        const nextProgress = nextProgressTransformer(progress);
        if (nextProgressSetter.current)
          nextProgressSetter.current(nextProgress);

        if (isBreakpointOrGreater(breakpoint, 'md')) {
          updateInteractiveState(nextProgress, isInView);
        }
      },
      setCarouselProgress(progress: number) {
        animation.current?.progress(progress);
      },
      getRect() {
        return $element.current?.getBoundingClientRect();
      },
    }),
    [updateInteractiveState],
  );

  useLayoutEffect(() => {
    enterProgressSetter.current = gsap.quickSetter(
      $element.current,
      '--card-enter-progress',
    ) as (value: number) => void;
    leaveProgressSetter.current = gsap.quickSetter(
      $element.current,
      '--card-leave-progress',
    ) as (value: number) => void;
    nextProgressSetter.current = gsap.quickSetter(
      $element.current,
      '--card-next-progress',
    ) as (value: number) => void;
  }, []);

  useLayoutEffect(() => {
    if (
      breakpoint &&
      isBreakpointOrGreater(breakpoint, 'lg') &&
      animation.current
    ) {
      // kill mobile animation if no longer on mobile
      animation.current.kill();
    }
  }, [breakpoint, isComputedStyleComplete, index]);

  // hide glow on `sm` breakpoint if user is dragging the slider and breakpoint is currently `sm`
  const isGlowHiddenSm =
    isGlowHidden && breakpoint && !isBreakpointOrGreater(breakpoint, 'lg');

  return (
    <div
      ref={$element}
      className={cn(
        className,
        styles.cardWrapper,
        isGlowHiddenSm && styles.isGlowHiddenSm,
        index === 0 && styles.firstItem,
      )}
      style={
        {
          '--reverse-index': total - index - 1,
          '--card-index': index,
        } as CSSProperties
      }
    >
      {content && (
        <Card
          layers={layers}
          content={content}
          isOutro={index < total}
          isInteractive={isInteractive}
          bleedOrientation={bleedOrientation}
          innerClassNames={{
            content: styles.content,
          }}
        />
      )}
    </div>
  );
};

export default forwardRef(CardWrapper);
