import { cn } from '~/utils';

import allLockupStyles from './allLockup.module.css';
import announcementBannerStyles from './announcementBanner.module.css';
import bodyXLManifestoStyles from './bodyXLManifesto.module.css';
import bodyXLSpeedbumpStyles from './bodyXLSpeedbump.module.css';
import buttonCardStyles from './buttonCard.module.css';
import cardGridEditorialStyles from './cardGridEditorial.module.css';
import caseStudyIntroStyles from './caseStudyIntro.module.css';
import caseStudyStatsStyles from './caseStudyStats.module.css';
import caseStudyTextStyles from './caseStudyText.module.css';
import floatingVideoPreviewStyles from './floatingVideoPreview.module.css';
import heroImageWallStyles from './heroImageWall.module.css';
import legacyPotholeStyles from './legacyPothole.module.css';
import prompterStyles from './prompter.module.css';
import statisticTitleBlockStyles from './statisticTitleBlock.module.css';
import { TextLockupConfig } from './TextLockups.types';
import title1BlockStyles from './title1Block.module.css';
import title2BlockStyles from './title2Block.module.css';
import title2PotholeStyles from './title2Pothole.module.css';
import title2SideBySideStyles from './title2SideBySide.module.css';
import title3BlockStyles from './title3Block.module.css';
import title4BlockStyles from './title4Block.module.css';
import title4FormStyles from './title4Form.module.css';
import title4QuoteStyles from './title4Quote.module.css';
import title4SideBySideStyles from './title4SideBySide.module.css';
import title4ToutStyles from './title4Tout.module.css';
import title5HatchetStyles from './title5Hatchet.module.css';
import title5SideBySideStyles from './title5SideBySide.module.css';
import title6BentoStyles from './title6Bento.module.css';
import title6ToutStyles from './title6Tout.module.css';
import titleCarouselBlockStyles from './titleCarouselBlock.module.css';

function all(): TextLockupConfig {
  return {
    wrapperClass: allLockupStyles.content,
    options: {
      block: {
        accents: {
          className: allLockupStyles.accents,
        },
        titles: {
          className: allLockupStyles.titles,
          title1: {
            className: allLockupStyles.title1,
          },
          title2: {
            className: allLockupStyles.title2,
          },
          title3: {
            className: allLockupStyles.title3,
          },
        },
        bodies: {
          className: allLockupStyles.bodies,
        },
      },
      list: {
        className: allLockupStyles.list,
      },
      listItem: {
        className: allLockupStyles.listItem,
      },
      marks: {
        className: allLockupStyles.marks,
        internalLink: {
          className: allLockupStyles.link,
        },
        link: {
          className: allLockupStyles.link,
        },
        mailTo: {
          className: allLockupStyles.link,
        },
        download: {
          className: allLockupStyles.link,
        },
        openConsent: {
          className: allLockupStyles.link,
        },
      },
      types: {
        className: allLockupStyles.types,
        'block.buttonGroup': {
          className: allLockupStyles.buttonGroup,
        },
      },
    },
  };
}

// New text lockups

function title1Block(): TextLockupConfig {
  return {
    wrapperClass: title1BlockStyles.content,
    nestedInGridWrapperClass: title1BlockStyles.contentNestedInGrid,
    options: {
      block: {
        accents: {
          className: title1BlockStyles.accents,
        },
        titles: {
          className: title1BlockStyles.titles,
        },
        bodies: {
          className: title1BlockStyles.bodies,
        },
      },
      types: {
        className: title1BlockStyles.types,
        'block.graphic': {
          className: title1BlockStyles.logo,
        },
        'block.buttonGroup': {
          className: title1BlockStyles.buttonGroup,
        },
      },
    },
  };
}

function title1StandoutDevice(): TextLockupConfig {
  return title1Block();
}

function title2Block(): TextLockupConfig {
  return {
    wrapperClass: title2BlockStyles.content,
    options: {
      block: {
        accents: {
          className: title2BlockStyles.accents,
          eyebrow: {
            className: title2BlockStyles.eyebrow,
          },
        },
        titles: {
          className: title2BlockStyles.titles,
        },
        bodies: {
          className: title2BlockStyles.bodies,
        },
      },
      marks: {
        openQuote: {
          className: title2BlockStyles.openQuote,
        },
      },
      types: {
        className: title2BlockStyles.types,
        'block.buttonGroup': {
          className: title2BlockStyles.buttonGroup,
        },
      },
    },
  };
}

function title2SideBySide(): TextLockupConfig {
  return {
    wrapperClass: title2SideBySideStyles.content,
    options: {
      block: {
        accents: {
          className: title2SideBySideStyles.accents,
          eyebrow: {
            className: title2SideBySideStyles.eyebrow,
          },
        },
        titles: {
          className: title2SideBySideStyles.titles,
        },
        bodies: {
          className: title2SideBySideStyles.bodies,
        },
      },
      marks: {
        openQuote: {
          className: title2SideBySideStyles.openQuote,
        },
      },
      types: {
        'block.buttonGroup': {
          className: title2SideBySideStyles.buttonGroup,
        },
      },
    },
  };
}
function title2Pothole(): TextLockupConfig {
  return {
    wrapperClass: title2PotholeStyles.content,
    options: {
      block: {
        accents: {
          className: title2PotholeStyles.accents,
          eyebrow: {
            className: title2PotholeStyles.eyebrow,
          },
        },
        titles: {
          className: title2PotholeStyles.titles,
        },
        bodies: {
          className: title2PotholeStyles.bodies,
        },
      },
      marks: {
        em: {
          className: title2PotholeStyles.emWithDash,
        },
        openQuote: {
          className: title2PotholeStyles.openQuote,
        },
      },
      types: {
        'block.buttonGroup': {
          className: title2PotholeStyles.buttonGroup,
        },
        'block.graphic': {
          className: title2PotholeStyles.graphic,
        },
      },
    },
  };
}

function title3Block(): TextLockupConfig {
  return {
    wrapperClass: title3BlockStyles.content,
    options: {
      block: {
        accents: {
          className: title3BlockStyles.accents,
          eyebrow: {
            className: title3BlockStyles.eyebrow,
          },
        },
        titles: {
          className: title3BlockStyles.titles,
        },
        bodies: {
          className: title3BlockStyles.bodies,
        },
      },
      types: {
        className: title3BlockStyles.types,
        'block.buttonGroup': {
          className: title3BlockStyles.buttonGroup,
        },
      },
    },
  };
}

function title4Block(): TextLockupConfig {
  return {
    wrapperClass: title4BlockStyles.content,
    options: {
      block: {
        accents: {
          className: title4BlockStyles.accents,
          eyebrow: {
            className: title4BlockStyles.eyebrow,
          },
          label: {
            className: title4BlockStyles.label,
          },
        },
        titles: {
          className: title4BlockStyles.titles,
        },
        bodies: {
          className: title4BlockStyles.bodies,
        },
      },
      types: {
        className: title4BlockStyles.types,

        'block.buttonGroup': {
          className: title4BlockStyles.ctas,
        },

        'block.graphic': {
          className: title4BlockStyles.graphic,
        },
      },
      marks: {
        em: {
          className: title4BlockStyles.emWithDash,
        },

        openQuote: {
          className: title4BlockStyles.openQuote,
        },
      },
    },
  };
}

function title4Form(): TextLockupConfig {
  return {
    wrapperClass: title4FormStyles.content,
    options: {
      block: {
        accents: {
          className: title4FormStyles.accents,
        },
        titles: {
          className: title4FormStyles.titles,
          tagName: 'span',
        },
        bodies: {
          className: title4FormStyles.bodies,
        },
      },
      types: {
        className: title4FormStyles.types,
        'block.graphic': {
          className: title4FormStyles.logo,
        },
      },
      marks: { em: { className: title4FormStyles.subtitle } },
    },
  };
}

function title4SideBySide(): TextLockupConfig {
  return {
    wrapperClass: title4SideBySideStyles.content,
    options: {
      block: {
        accents: {
          className: title4SideBySideStyles.accents,
          eyebrow: {
            className: title4SideBySideStyles.eyebrow,
          },
        },
        titles: {
          className: title4SideBySideStyles.titles,
          tagName: 'span',
        },
        bodies: {
          className: title4SideBySideStyles.bodies,
        },
      },
      types: {
        className: title4SideBySideStyles.types,
        'block.buttonGroup': {
          className: title4SideBySideStyles.buttonGroup,
        },
      },
      marks: { em: { className: title4SideBySideStyles.subtitle } },
    },
  };
}

function title4Tout(): TextLockupConfig {
  return {
    wrapperClass: title4ToutStyles.content,
    options: {
      block: {
        accents: {
          className: title4ToutStyles.accents,
        },
        titles: {
          className: title4ToutStyles.titles,
          tagName: 'span',
        },
        bodies: {
          className: title4ToutStyles.bodies,
        },
      },
      types: {
        className: title4ToutStyles.types,
        'block.graphic': {
          className: title4ToutStyles.logo,
        },
      },
      marks: { em: { className: title4ToutStyles.subtitle } },
    },
  };
}

function title4Quote(): TextLockupConfig {
  return {
    wrapperClass: title4QuoteStyles.content,
    options: {
      block: {
        accents: {
          className: title4QuoteStyles.accents,
        },
        titles: {
          className: title4QuoteStyles.titles,
        },
      },
      types: {
        'block.graphic': {
          className: title4QuoteStyles.graphic,
        },
      },
      marks: {
        em: {
          className: title4QuoteStyles.emWithDash,
        },

        openQuote: {
          className: title4QuoteStyles.openQuote,
        },
      },
    },
  };
}

function title5SideBySide(): TextLockupConfig {
  return {
    wrapperClass: title5SideBySideStyles.content,
    options: {
      block: {
        titles: {
          className: title5SideBySideStyles.titles,
        },
        bodies: {
          className: title5SideBySideStyles.bodies,
        },
      },
      types: {
        'block.buttonGroup': {
          className: title5SideBySideStyles.buttonGroup,
        },
      },
    },
  };
}

function title5Hatchet(): TextLockupConfig {
  return {
    wrapperClass: title5HatchetStyles.content,
    options: {
      block: {
        accents: {
          className: title5HatchetStyles.accents,
        },
        titles: {
          className: title5HatchetStyles.titles,
        },
        bodies: {
          className: title5HatchetStyles.bodies,
        },
      },
    },
  };
}

function title6Bento(): TextLockupConfig {
  return {
    wrapperClass: title6BentoStyles.content,
    options: {
      block: {
        accents: {
          className: title6BentoStyles.accents,
        },
        titles: {
          className: title6BentoStyles.titles,
        },
        bodies: {
          className: title6BentoStyles.bodies,
        },
      },
      types: {
        className: title6BentoStyles.types,
        'block.buttonGroup': {
          className: title6BentoStyles.buttonGroup,
        },
      },
    },
  };
}

function title6Tout(): TextLockupConfig {
  return {
    wrapperClass: title6ToutStyles.content,
    options: {
      block: {
        accents: {
          className: title6ToutStyles.accents,
        },
        titles: {
          className: title6ToutStyles.titles,
        },
        bodies: {
          className: title6ToutStyles.bodies,
        },
      },
      types: {
        className: title6ToutStyles.types,
        'block.buttonGroup': {
          className: title6ToutStyles.buttonGroup,
        },
      },
    },
  };
}

function statisticTile(): TextLockupConfig {
  return {
    wrapperClass: statisticTitleBlockStyles.content,
    options: {
      block: {
        accents: {
          label: {
            className: statisticTitleBlockStyles.label,
          },
          eyebrow: {
            className: statisticTitleBlockStyles.eyebrow,
          },
        },
        titles: {
          className: statisticTitleBlockStyles.title,
        },
      },
      types: {
        'block.graphic': {
          className: statisticTitleBlockStyles.logo,
        },
      },
    },
  };
}

function bodyXLManifesto(): TextLockupConfig {
  return {
    wrapperClass: bodyXLManifestoStyles.content,
    options: {
      block: {
        bodies: {
          className: bodyXLManifestoStyles.bodies,
        },
      },
      types: {
        'block.buttonGroup': {
          className: bodyXLManifestoStyles.buttonGroup,
        },
      },
    },
  };
}

function titleCarouselBlock(): TextLockupConfig {
  return {
    wrapperClass: cn(titleCarouselBlockStyles.content),
    options: {
      block: {
        accents: {
          className: titleCarouselBlockStyles.accents,
          eyebrow: {
            className: titleCarouselBlockStyles.eyebrow,
          },
          label: {
            className: titleCarouselBlockStyles.label,
          },
        },
        titles: {
          className: titleCarouselBlockStyles.titles,
        },
        bodies: {
          className: titleCarouselBlockStyles.bodies,
        },
      },
      types: {
        className: titleCarouselBlockStyles.types,

        'block.buttonGroup': {
          className: titleCarouselBlockStyles.ctas,
        },

        'block.graphic': {
          className: titleCarouselBlockStyles.graphic,
        },
      },
      marks: {
        em: {
          className: title4BlockStyles.emWithDash,
        },

        openQuote: {
          className: title4BlockStyles.openQuote,
        },
      },
    },
  };
}

function bodyXLSpeedbump(): TextLockupConfig {
  return {
    wrapperClass: bodyXLSpeedbumpStyles.content,
    options: {
      block: {
        accents: {
          className: bodyXLSpeedbumpStyles.accents,
          eyebrow: {
            className: bodyXLSpeedbumpStyles.eyebrow,
          },
          label: {
            className: bodyXLSpeedbumpStyles.label,
          },
        },
        titles: {
          className: bodyXLSpeedbumpStyles.titles,
          title4: {
            className: bodyXLSpeedbumpStyles.bodyExtraLarge,
          },
        },
        bodies: {
          className: bodyXLSpeedbumpStyles.bodies,
        },
      },
      types: {
        className: bodyXLSpeedbumpStyles.types,

        'block.buttonGroup': {
          className: bodyXLSpeedbumpStyles.ctas,
        },

        'block.graphic': {
          className: bodyXLSpeedbumpStyles.graphic,
        },
      },
      marks: {
        em: {
          className: bodyXLSpeedbumpStyles.emWithDash,
        },

        openQuote: {
          className: bodyXLSpeedbumpStyles.openQuote,
        },
      },
    },
  };
}

function buttonCard(): TextLockupConfig {
  return {
    wrapperClass: buttonCardStyles.content,
    options: {
      block: {
        bodies: {
          className: buttonCardStyles.bodies,
        },
      },
      types: {
        'block.buttonGroup': {
          className: buttonCardStyles.buttonGroup,
        },
      },
    },
  };
}

function body(): TextLockupConfig {
  return {
    wrapperClass: allLockupStyles.content,
    options: {
      block: {
        bodies: {
          className: allLockupStyles.bodies,
        },
      },
      types: {
        className: allLockupStyles.types,
      },
    },
  };
}

function announcementBanner(): TextLockupConfig {
  return {
    options: {
      block: {
        bodies: {
          body: {
            className: announcementBannerStyles.body,
          },
        },
      },
      marks: {
        link: {
          className: announcementBannerStyles.link,
        },
        internalLink: {
          className: announcementBannerStyles.link,
        },
        download: {
          className: announcementBannerStyles.link,
        },
      },
    },
  };
}

function formDisclaimer(): TextLockupConfig {
  return {
    options: {},
  };
}

function cardGridEditorial(): TextLockupConfig {
  return {
    wrapperClass: cardGridEditorialStyles.content,
    options: {
      block: {
        titles: {
          className: cardGridEditorialStyles.titles,
        },
        bodies: {
          className: cardGridEditorialStyles.bodies,
        },
      },
      types: {},
    },
  };
}

function prompter(): TextLockupConfig {
  return {
    wrapperClass: prompterStyles.content,
    options: {
      block: {
        titles: {
          className: prompterStyles.titles,
        },
      },
    },
  };
}

function caseStudyText(): TextLockupConfig {
  return {
    wrapperClass: caseStudyTextStyles.content,
    options: {
      block: {
        titles: {
          className: caseStudyTextStyles.titles,
        },
        bodies: {
          className: caseStudyTextStyles.bodies,
        },
      },
    },
  };
}

function caseStudyStats(): TextLockupConfig {
  return {
    wrapperClass: caseStudyStatsStyles.content,
    options: {
      block: {
        bodies: {
          className: caseStudyStatsStyles.bodies,
        },
      },
    },
  };
}

function caseStudyIntro(): TextLockupConfig {
  return {
    wrapperClass: caseStudyIntroStyles.content,
    options: {
      block: {
        titles: {
          className: caseStudyIntroStyles.titles,
        },
        bodies: {
          className: caseStudyIntroStyles.bodies,
        },
      },
      marks: {
        link: {
          className: caseStudyIntroStyles.link,
        },
        download: {
          className: caseStudyIntroStyles.link,
        },
      },
      types: {
        'block.buttonGroup': {
          className: caseStudyIntroStyles.buttonGroup,
        },
      },
    },
  };
}

function heroImageWall(): TextLockupConfig {
  return {
    wrapperClass: heroImageWallStyles.content,
    nestedInGridWrapperClass: heroImageWallStyles.contentNestedInGrid,
    options: {
      block: {
        accents: {
          className: heroImageWallStyles.accents,
        },
        titles: {
          className: heroImageWallStyles.titles,
        },
        bodies: {
          className: heroImageWallStyles.bodies,
        },
      },
      types: {
        className: heroImageWallStyles.types,
        'block.graphic': {
          className: heroImageWallStyles.logo,
        },
        'block.buttonGroup': {
          className: heroImageWallStyles.buttonGroup,
        },
      },
    },
  };
}

function legacyPothole(): TextLockupConfig {
  return {
    wrapperClass: legacyPotholeStyles.content,
    options: {
      block: {
        accents: {
          className: legacyPotholeStyles.accents,
          eyebrow: {
            className: legacyPotholeStyles.eyebrow,
          },
          label: {
            className: legacyPotholeStyles.label,
          },
        },
        titles: {
          className: legacyPotholeStyles.titles,
        },
        bodies: {
          className: legacyPotholeStyles.bodies,
        },
      },
      marks: {
        openQuote: {
          className: legacyPotholeStyles.openQuote,
        },
        em: {
          className: legacyPotholeStyles.emWithDash,
        },
      },
      types: {
        className: legacyPotholeStyles.types,
        'block.buttonGroup': {
          className: legacyPotholeStyles.buttonGroup,
        },
      },
    },
  };
}
function floatingVideoPreview(): TextLockupConfig {
  return {
    wrapperClass: floatingVideoPreviewStyles.content,
    options: {
      block: {
        titles: {
          className: floatingVideoPreviewStyles.titles,
        },
      },
    },
  };
}

export {
  all,
  title1Block,
  title1StandoutDevice,
  title2Block,
  title2SideBySide,
  title2Pothole,
  title3Block,
  title4Block,
  title4Form,
  title4SideBySide,
  title4Tout,
  title4Quote,
  title5Hatchet,
  title5SideBySide,
  title6Bento,
  title6Tout,
  statisticTile,
  titleCarouselBlock,
  body,
  bodyXLManifesto,
  bodyXLSpeedbump,
  buttonCard,
  formDisclaimer,
  cardGridEditorial,
  announcementBanner,
  prompter,
  caseStudyText,
  caseStudyStats,
  caseStudyIntro,
  heroImageWall,
  legacyPothole,
  floatingVideoPreview,
};
