import { enqueueOrRun } from '@frameio/segment-ot';

import getIntegrations from './getIntegrations';
import { getOneTrustConsent } from './getOneTrustConsent';
const trackEventSegment = (
  eventName: string,
  properties: Record<string, string>,
) => {
  enqueueOrRun({
    event: () =>
      window.analytics &&
      window.analytics.track(eventName, properties, {
        context: getOneTrustConsent(),
        integrations: getIntegrations(),
      }),
  });
};

export default trackEventSegment;
