'use client';

import Graphic from '~/components/atoms/Graphic/Graphic';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import { cn } from '~/utils';

import styles from './ToutCard.module.css';
import { ToutCardProps } from './ToutCard.types';

const ToutCard = ({
  content,
  icon,
  tallestWrapperHeight,
  graphicType,
}: ToutCardProps) => {
  const isLogo = graphicType === 'logo';

  return (
    <div className={cn(styles.toutCard)}>
      {icon && (
        <div
          className={cn(styles.iconWrapper, isLogo && styles.isLogo)}
          style={{
            height: tallestWrapperHeight,
          }}
        >
          <Graphic {...icon} className={styles.icon} />
        </div>
      )}
      <TextLockup
        className={styles.content}
        lockupOptions={content.lockupOptions}
        value={content.blocks}
      />
    </div>
  );
};

export default ToutCard;
