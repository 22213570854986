'use client';

import { gsap } from 'gsap';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import EnhancedMedia from '~/components/molecules/EnhancedMedia/EnhancedMedia';
import Media from '~/components/molecules/Media/Media';
import useUIStore from '~/state/ui';
const UIStore = useUIStore;
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import { cn, isBreakpointOrSmaller, useScrollProgress } from '~/utils';

import ModuleWrapper from '../../ModuleWrapper/ModuleWrapper';
import LogoWall from '../LogoWall/LogoWall';
import logoWallFormatter from '../LogoWall/LogoWall.formatter';
import { LogoWallProps } from '../LogoWall/LogoWall.types';
import Device from './Device/Device';
import DeviceThemeToggle from './DeviceThemeToggle/DeviceThemeToggle';
import InteractiveStack from './InteractiveStack/InteractiveStack';
import Snow from './Snow/Snow';
import { SnowRef } from './Snow/Snow.types';
import styles from './StandoutDevice.module.css';
import deviceHeroStore from './StandoutDevice.store';
import { StandoutDeviceProps } from './StandoutDevice.types';

const StandoutDevice = (props: StandoutDeviceProps) => {
  const { className, background, video: overlay } = props;
  const bgEnhMedia = background?.media;

  const $module = useRef<HTMLDivElement>(null);
  const $container = useRef<HTMLDivElement>(null);
  const $intro = useRef<HTMLDivElement>(null);
  const $titleWrapper = useRef<HTMLDivElement>(null);
  const $deviceWrapper = useRef<HTMLDivElement>(null);
  const $stackWrapper = useRef<HTMLDivElement>(null);
  const $media = useRef<HTMLElement>(null);
  const $deviceContainer = useRef<HTMLDivElement>(null);
  const $snow = useRef<SnowRef>(null);
  const $stackIntroTracker = useRef<HTMLDivElement>(null);
  const $logoWall = useRef<HTMLDivElement>(null);

  const breakpoint = useUIStore((state) => state.breakpoint);
  const [isInteractive, setIsInteractive] = useState<boolean>(false);

  const snowOpacitySetter = useRef<(value: number) => void>();
  const [isDeviceToggleVisible, setIsDeviceToggleVisible] = useState(false);

  // Create an instance of LogoWallProps so that we can instantiate the
  // module. The StandoutDevice fragment fetches the global list of logos
  // and passes it through here.
  const logoWallProps = useMemo<LogoWallProps>(() => {
    const lwProps: LogoWallProps = {
      _id: 'logo-wall',
      _key: 'logo-wall-key',
      _type: 'module.logoWall',
      name: 'Logo Wall',
      logos: props.logos,
      className: styles.logoWall,
      desktopLogos: [],
      moduleIndex: 1,
      sectionIndex: 0,
      sectionId: 'standout-device-section',
      isFirstModule: false,
      isLastModule: false,
      isLastSection: false,
      isCoveredModule: false,
      containsPortableText: false,
      isRevealedModule: false,
      sectionHasFab: false,
      followsFab: false,
      precedesFab: false,
      isVariant: false,
    };
    logoWallFormatter(lwProps);
    return lwProps;
  }, [props.logos]);

  useEffect(() => {
    if (!$container.current || !bgEnhMedia?.sanityMedia) {
      return;
    }

    if (bgEnhMedia?.sanityMedia?.mediaType !== 'image') {
      console.warn('background media not set to image');
      return;
    }

    const { asset, hotspot: hot } = bgEnhMedia.sanityMedia;

    if (!hot) {
      console.warn('no hotspot found for device background media');
      return;
    }

    const computeVariables = () => {
      const rect = $deviceContainer.current?.getBoundingClientRect();
      if (!rect) {
        return;
      }
      // on small breakpoints use a fixed scaledown to better fit devices
      let smScaleFactor = 1.0;
      if (isBreakpointOrSmaller(breakpoint, 'sm')) {
        smScaleFactor = 0.75;
      }
      // scale the image according container & hotspot sizes
      const toScale = rect.width / ((hot.width * asset.width) / smScaleFactor);
      const imgWidth = Math.ceil(asset.width * toScale);
      const imgHeight = Math.ceil(asset.height * toScale);
      // compute edges & image offset. for x-axis account for the scale factor
      const imgLeft = -((hot.x - (hot.width / smScaleFactor) * 0.5) * imgWidth);
      const imgTop = -((hot.y - hot.height * 0.5) * imgHeight);
      // update the height of the container so it matches device height
      const containerHeight = hot.height * imgHeight;

      if ($snow.current) {
        const state = UIStore.getState();
        const winWidth = state.windowWidth || window.innerWidth;
        const winHeight = state.windowHeight || window.innerHeight;
        const mScaleW = imgWidth / winWidth;
        const mScaleH = imgHeight / winHeight;

        $snow.current.setMaskScale(mScaleW, mScaleH);
        $snow.current.setMaskPosition(
          ((imgWidth - winWidth) / winWidth) * 0.5,
          (imgHeight - winHeight) / winHeight,
        );
      }

      gsap.set($container.current, {
        '--img-scale': `${toScale}`,
        '--img-width': `${imgWidth}px`,
        '--img-left': `${imgLeft}px`,
        '--img-top': `${imgTop}px`,
        '--container-height': `${containerHeight}px`,
      });
    };

    computeVariables();

    return UIStore.subscribe((state) => [state.windowWidth], computeVariables);
  }, [bgEnhMedia, breakpoint]);

  const checkIsInteractive = useCallback(
    (progress: number, isInView: boolean) => {
      if (progress > 0 && progress < 1 && isInView) {
        !isInteractive && setIsInteractive(true);
      } else {
        isInteractive && setIsInteractive(false);
      }
    },
    [isInteractive],
  );

  useScrollProgress($deviceWrapper, checkIsInteractive);

  useScrollProgress($intro, (progress: number) => {
    deviceHeroStore.setState({ moduleIntroProgress: progress });
  });

  useScrollProgress($stackIntroTracker, (progress: number) => {
    deviceHeroStore.setState({ stackIntroProgress: progress });
  });

  useEffect(() => {
    if ($snow.current) {
      snowOpacitySetter.current = gsap.quickSetter(
        $snow.current.$container.current,
        'opacity',
      ) as (value: number) => void;
    }
  }, [$snow]);

  useEffect(() => {
    const title = $titleWrapper.current;
    const device = $deviceWrapper.current;
    const stack = $stackWrapper.current;
    const media = $media.current;
    const logoWall = $logoWall.current;
    const { min, max } = Math;

    if (!title || !device || !stack || !media || !logoWall) {
      return;
    }

    return deviceHeroStore.subscribe((state) => {
      const moduleIntroProgress = state.moduleIntroProgress;
      const stackProgress = state.stackProgress;
      const stackIntroProgress = state.stackIntroProgress;
      const ease = 'expo.out';
      let duration = 1;

      if ($snow.current?.$overlay.current) {
        gsap.set($snow.current.$overlay.current, {
          '--bottom-gradient-opacity': stackProgress,
        });
      }

      if (isBreakpointOrSmaller(breakpoint, 'md')) {
        duration = 0.5;

        // NOTE: the parallax is currently disabled for mobile & tablet until we can figure out the scroll position reporting issue.
        // Right now, if you flick the screen quickly on the actual device, there is a delay to when the device starts reporting scroll
        // values after the section is already in view, which causes a stutter in the parallax.

        gsap.killTweensOf(title);
        gsap.to(title, {
          ease,
          duration,
          y: 0,
          opacity: 1 - min(0.95, max(0.75, moduleIntroProgress) - 0.75) / 0.2,
        });

        gsap.killTweensOf(device);
        gsap.to(device, {
          ease,
          duration,
          y: 0,
          opacity: 1,
        });

        gsap.killTweensOf(stack);
        gsap.to(stack, {
          ease,
          duration,
          y: 0,
          opacity: 1,
        });

        const rect = $media.current?.getBoundingClientRect();
        if (rect) {
          $snow.current?.setMaskOffset(0, rect.top);
        }

        return;
      }

      gsap.killTweensOf(title);
      gsap.to(title, {
        ease,
        duration,
        // move title down to meet with the device
        y: `${moduleIntroProgress * 4}rem`,
      });
      gsap.to(title, {
        ease,
        duration: 0.2,
        // title should fade out just as the device is overtaking it
        opacity: 1 - min(0.95, max(0.75, moduleIntroProgress) - 0.75) / 0.2,
      });

      gsap.killTweensOf(device);
      gsap.to(device, {
        ease,
        duration,
        y: `${
          // move arm upwards to overlap with title
          5 +
          moduleIntroProgress * -10 +
          // continue moving arm once it is sticky
          stackProgress * -10
        }rem`,
        // the arm should fade out between the first and third card centering
        opacity: 1 - (min(0.6, max(0.2, stackProgress)) - 0.2) / 0.4,
        // in order to match the mask to the arm & device we need to sample
        // the position of the media element rectangle
        onUpdate: () => {
          const rect = $media.current?.getBoundingClientRect();
          if (rect && $snow.current) {
            $snow.current.setMaskAlpha(
              Number(gsap.getProperty(device, 'opacity')),
            );
            $snow.current.setMaskOffset(0, rect.top);
          }
        },
      });

      gsap.killTweensOf(logoWall);
      gsap.to(logoWall, {
        ease,
        duration,
        y: `${stackIntroProgress * -10}rem`,
      });

      gsap.killTweensOf(stack);
      gsap.to(stack, {
        ease,
        duration,
        y: `${20 - stackIntroProgress * 20}rem`,
      });
    });
  }, [breakpoint]);

  // tracks the progress of the top edge of the section from the bottom of the viewport to the top of the viewport (value from 0 to 1)
  const onEnterCardsProgress = useCallback((progress: number) => {
    if (snowOpacitySetter.current) {
      snowOpacitySetter.current(1 - progress);

      gsap.set($logoWall.current, { opacity: 1 - progress });
    }
    setIsDeviceToggleVisible(progress > 0.5);
  }, []);
  useScrollProgress($stackIntroTracker, onEnterCardsProgress, {
    startOnMiddleOfScreen: true,
  });

  return (
    <ModuleWrapper
      className={cn(className, styles.module)}
      {...props}
      ref={$module}
    >
      <div className={styles.container} ref={$container}>
        <div className={styles.titleWrapper} ref={$titleWrapper}>
          <TextLockup
            value={props.title.blocks}
            className={styles.title}
            lockupOptions={props.title.lockupOptions}
          />
        </div>
        <div ref={$intro}>
          {/* This element is required to get an accurate reading of intro progress. Tracking the element below does not work as we are transforming it. */}
        </div>
        <div className={styles.deviceWrapper} ref={$deviceWrapper}>
          <Device layer={overlay} isInteractive={isInteractive} />
          <div className={styles.deviceContainer} ref={$deviceContainer}>
            {bgEnhMedia && (
              <EnhancedMedia
                overlay={background.overlay}
                className={styles.enhMedia}
              >
                <Media
                  sanityMedia={bgEnhMedia.sanityMedia}
                  quality={90}
                  forceIsInView={true}
                  autoResize={false}
                  fixedAspectRatio={true}
                  ref={$media}
                  className={styles.armMedia}
                />
              </EnhancedMedia>
            )}
          </div>
        </div>
        <Snow particles={props.particles} mask={props.mask} ref={$snow} />
        <LogoWall ref={$logoWall} {...logoWallProps} />
        <div className={styles.stackWrapper} ref={$stackWrapper}>
          <div ref={$stackIntroTracker}></div>
          <InteractiveStack cards={props.cards} />
        </div>
      </div>
      <DeviceThemeToggle isVisible={isDeviceToggleVisible} />
    </ModuleWrapper>
  );
};

export default StandoutDevice;
