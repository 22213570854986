import Cookies from 'js-cookie';

const oneTrustCookieName = 'OptanonConsent';

function formatValue(value: string) {
  if (value === '1') {
    return true;
  }
  if (value === '0') {
    return false;
  }
  return value;
}

function getOneTrustCookieGroupsArray() {
  const oneTrustCookieValue = Cookies.get(oneTrustCookieName);
  if (!oneTrustCookieValue) return [];

  const params = new URLSearchParams(oneTrustCookieValue);
  const groupsString = params.get('groups');
  if (!groupsString) return [];

  return groupsString.split(',');
}

export function getOneTrustConsent() {
  const groupsArray = getOneTrustCookieGroupsArray();
  if (groupsArray.length === 0) return {};

  const categoryPreferences: Record<string, unknown> = {};
  groupsArray.forEach((group) => {
    const [key, value] = group.split(':');
    categoryPreferences[key] = formatValue(value);
  });

  return {
    consent: {
      categoryPreferences,
    },
  };
}
