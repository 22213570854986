import { z } from 'zod';

/**
 * For split tests (buckets with no criteria) we only need a sigle bucket with
 * the current implementation. We will use this bucket slug so we can visually
 * separate buckets.
 */
export const DEFAULT_SPLIT_TEST_BUCKET = 'a-b-split-test';

/**
 * The Audience Criteria Type represents the different criteria we can apply
 * to any audience
 */
export const AudienceCriteriaType = z.enum([
  'employee-count',
  'company-name',
  'geo-city',
  'geo-country',
]);

export type AudienceCriteriaType = z.infer<typeof AudienceCriteriaType>;

/**
 * The Criteria type represents the audience predicate that is used to match
 * a visitor to any given audience
 */
export const Criteria = z.discriminatedUnion('_type', [
  z.object({
    _type: z.literal(AudienceCriteriaType.Enum['company-name']),
    value: z.array(z.string()),
  }),
  z.object({
    _type: z.literal(AudienceCriteriaType.Enum['employee-count']),
    value: z.number(),
  }),
  z.object({
    _type: z.literal(AudienceCriteriaType.Enum['geo-city']),
    value: z.string(),
  }),
  z.object({
    _type: z.literal(AudienceCriteriaType.Enum['geo-country']),
    value: z.string(),
  }),
]);

export type Criteria = z.infer<typeof Criteria>;

/**
 * A bucket represents a traffic funnel. A page can split traffic into
 * different buckets, either as part of an audience or as a simple a/b test.
 */
export const Bucket = z.object({
  name: z.string(),
  slug: z.string(),
  criteria: z.array(Criteria),
});

export type Bucket = z.infer<typeof Bucket>;

/**
 * The page variant info object is a reference to a variant by type. The
 * variantType is a reference to a bucket slug.
 */
export const PageVariantInfo = z.object({
  variantBase: z.string().uuid(),
  variantId: z.string().uuid(),
  variantName: z.string().optional(),
  variantType: z.string(),
  weight: z.number(),
  slug: z.string(),
  redirect: z
    .object({
      id: z.string(),
      slug: z.string(),
    })
    .nullable()
    .optional(),
  redirect_target_of: z.array(
    z.object({
      id: z.string(),
      slug: z.string(),
    }),
  ),
});

export type PageVariantInfo = z.infer<typeof PageVariantInfo>;

/**
 * An object representing a page and all of its variants.
 */
export const PageVariant = z.object({
  _id: z.string().uuid(),
  slug: z.string(),
  weight: z.number(),
  pageVariants: z.array(PageVariantInfo),
});

export type PageVariant = z.infer<typeof PageVariant>;
