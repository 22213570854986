import { CMSGraphicProps } from '~/components/atoms/Graphic/Graphic.types';
import { PortableTextCustomValueData } from '~/components/molecules/PortableText/PortableText.types';
import { MoleculeBase } from '~/components/organisms/ModularComponent/ModularComponent.types';

export const MOBILE_SIZE_TEXT_LIMIT = 70;

export type CMSToutsProps = {
  touts: Tout[];
  title?: PortableTextCustomValueData;
};

export type ToutsProps = MoleculeBase &
  CMSToutsProps & {
    isColumnLayout?: boolean;
    tallestWrapperHeight?: number;
    containerClassName?: string;
  };

// individual tout
export type Tout = MoleculeBase & {
  content: PortableTextCustomValueData;
  icon: CMSGraphicProps;
  graphicType?: 'logo' | 'icon';
};
