import { enqueueOrRun } from '@frameio/segment-ot';

import analyticsStore from './Analytics.store';
import getAnalyticsSlugFromPath from './getAnalyticsSlugFromPath';
import getBaseTrackingData from './getBaseTrackingData';
import getIntegrations from './getIntegrations';
import { getOneTrustConsent } from './getOneTrustConsent';
import trackEventSegment from './trackEventSegment';

/**
 * @param pathname Should match usePathname from `next/navigation`
 * @param options An object which requires { integrations: {} } child object.
 */
const trackPageChangeSegment = (pathname = '') => {
  // pageslug converts the pathname to a hyphen-separated slug
  const pageSlug = getAnalyticsSlugFromPath(pathname) || 'home';

  // if window is available, generate the url with a combination of window origin and router pathname
  // it's necessary to use the router pathname because it changes before the page (and url) renders
  // if window is not available, segment will default to sniffing the user's location.url
  const url =
    typeof window !== 'undefined'
      ? pathname && pathname !== '/'
        ? `${location.origin}${pathname}${location.search}`
        : location.origin
      : undefined;

  // page tracking method
  // if homepage, pathname will be "/", empty strings are false positives
  if (url) {
    // analytics.page(category, name, properties, undocumentedFieldWhichContainsIntegrations)
    // Segment will setup the `properties` object for us, no need to do it ourselves
    // https://segment.com/docs/connections/spec/page/#properties
    enqueueOrRun({
      event: () =>
        window.analytics &&
        window.analytics.page(
          undefined,
          undefined,
          {
            ...getBaseTrackingData(),
            url,
          },
          {
            context: getOneTrustConsent(),
            integrations: getIntegrations(),
          },
        ),
    });
  }

  const pageViewedTrackingData = {
    ...getBaseTrackingData(),
    loggedIn: analyticsStore.getState().loggedIn.toString(),
  };

  // event tracking method
  trackEventSegment(`page-viewed-${pageSlug}`, pageViewedTrackingData);
  trackEventSegment('page-viewed', pageViewedTrackingData);
};

export default trackPageChangeSegment;
