import { gsap } from 'gsap';
import { useEffect, useRef } from 'react';

import { CMSAsset } from '~/components/atoms/Image/Image.types';
import { CMSMediaProps } from '~/components/molecules/Media/Media.types';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import MediaLayer from '~/components/organisms/modules/StandoutDevice/MediaLayer/MediaLayer';
import useUIStore from '~/state/ui';
import { cn, isBreakpointOrSmaller } from '~/utils';

import useDeviceStore from '../../StandoutDevice.store';
import styles from './Card.module.css';
import { CardProps } from './Card.types';

const getMediaAsset = (media: CMSMediaProps): CMSAsset | undefined => {
  switch (media.sanityMedia.mediaType) {
    case 'image':
      return media.sanityMedia.asset;
    case 'video':
      return media.sanityMedia.thumbnail?.asset;
  }
};

const Card = (props: CardProps) => {
  const classes = props?.innerClassNames;
  const $mediaRef = useRef<HTMLDivElement>(null);
  const $fgMediaWrapper = useRef<HTMLDivElement>(null);
  const theme = useDeviceStore((state) => state.theme);
  const breakpoint = useUIStore((state) => state.breakpoint);

  const fgVariant =
    theme === 'dark' ? props?.layers[1]?.default : props?.layers[1]?.variant;
  const fgMedia = fgVariant?.media;

  useEffect(() => {
    const reposition = () => {
      if (!$mediaRef.current || !$fgMediaWrapper.current || !fgMedia) {
        return;
      }
      const rect = $mediaRef.current.getBoundingClientRect();
      const asset = getMediaAsset(fgMedia);
      if (!asset) {
        return;
      }

      const isSm = isBreakpointOrSmaller(breakpoint, 'sm');

      // contain the foreground element within the card, scale down by the largest axis
      let width = rect.width * 0.8;
      let height = width / asset.aspectRatio;
      if (height > rect.height) {
        height = rect.height * 0.8;
        width = height * asset.aspectRatio;
      }
      // for mobile view & landscape assets, compute the size based on height instead
      if (isSm && asset.aspectRatio > 1) {
        height = rect.height * 0.7;
        width = height * asset.aspectRatio;
      }
      // assets are centered within the card unless they overflow, in which case overflow
      // to the right for now. perhaps make this controllable via CMS
      let left = rect.width / 2 - width / 2;
      if (isSm && left < rect.width * 0.1) {
        left = rect.width * 0.1;
      }
      // always center vertically
      const top = rect.height / 2 - height / 2;

      gsap.set($fgMediaWrapper.current, {
        '--media-width': `${Math.round(width)}px`,
        '--media-height': `${Math.round(height)}px`,
        '--media-side-offset': `${Math.round(left)}px`,
        '--media-top': `${Math.round(top)}px`,
      });
    };
    reposition();
    window.addEventListener('resize', reposition);
    return () => {
      window.removeEventListener('resize', reposition);
    };
  }, [$mediaRef, fgMedia, breakpoint]);

  const haveBgLayer = props.layers[0] ?? false;
  const haveFgLayer = props.layers[1] ?? false;
  const isInteractive = props.isInteractive;

  return (
    <div className={styles.card}>
      <div
        className={cn(
          styles.media,
          classes?.media,
          props.isOutro && styles.mediaOutro,
          props.bleedOrientation && styles[`bleeds${props.bleedOrientation}`],
        )}
        ref={$mediaRef}
      >
        <div className={styles.mediaScaler}>
          {haveBgLayer && (
            <div className={styles.backgroundMediaWrapper}>
              <MediaLayer
                layer={props.layers[0]}
                isInteractive={isInteractive && !haveFgLayer}
                innerClassNames={{
                  baseMedia: styles.backgroundMediaContainer,
                  variantMedia: styles.backgroundMediaContainer,
                }}
              />
            </div>
          )}
          {haveFgLayer && (
            <div
              className={styles.foregroundMediaWrapper}
              ref={$fgMediaWrapper}
            >
              <MediaLayer
                layer={props.layers[1]}
                isInteractive={isInteractive}
                innerClassNames={{
                  baseMedia: styles.foregroundMediaContainer,
                  variantMedia: styles.foregroundMediaContainer,
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className={cn(styles.content, classes?.content)}>
        <TextLockup
          className={styles.contentText}
          value={props.content.blocks}
          lockupOptions={props.content.lockupOptions}
        />
      </div>
    </div>
  );
};

export default Card;
